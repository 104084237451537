import styled from 'styled-components';
import Img from 'gatsby-image';

export const Layout = styled.div`
  margin: 0 1rem 1rem 1rem;
`;

export const SplitLayout = styled(Layout)`
  overflow-x: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  color: ${props => props.theme.white()};
  @media (max-width: ${props => props.theme.mobileBreakPoint}) {
    grid-template-columns: 1fr;
  }
  .sideA,
  .sideB {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
`;

export const SplitSection = styled.div`
  width: 100%;
  margin: 10px 0;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
  div {
    /* position: relative; */
  }
  @media (max-width: ${props => props.theme.mobileBreakPoint}) {
    .contentSide {
      grid-area: contentSide;
    }
    .imageSide {
      grid-area: imageSide;
    }
    grid-template-areas:
      'imageSide'
      'contentSide';
    grid-template-columns: 1fr;
    .verticalHeader {
      display: none;
    }
  }
`;

export const Content = styled.div`
  h3,
  h4,
  hr {
    margin: 40px 0;
  }
  h4 {
    font-size: 0.8em;
    font-weight: 200;
  }
  h3 {
    font-weight: 800;
  }
  @media (max-width: 700px) {
    p {
      font-size: 0.8em;
    }
  }
`;

export const Image = styled(Img)`
  background-size: cover;
  filter: grayscale(100%);
  height: 100%;
  max-height: calc(100vh - 50px);
  @media (min-width: ${props => props.theme.mobileBreakPoint}) {
    height: calc(100vh - 50px);
  }
`;
