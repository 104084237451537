import React from 'react';
import Page from '../components/Page';
import { graphql } from 'gatsby';
import {
  Layout,
  SplitSection,
  Content,
  Image,
} from '../components/styles/Layout';
import WipeTransition from '../components/WipeTransition';

export const query = graphql`
  query {
    image1: file(
      relativePath: { eq: "chefMiguelValdez_libertyCallDistillingCompany.jpg" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "albertAllison.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const Team = ({ data }) => {
  return (
    <Layout>
      <SplitSection>
        <div className="imageSide">
          <Image
            fluid={data.image1.childImageSharp.fluid}
            alt="Chef Miguel Valdez"
          />
        </div>
        <div className="contentSide">
          <Content className="content">
            <div className="details">
              <hr />
              <h4 className="title">Head Chef</h4>
              <h3 className="title">Miguel Valdez</h3>
              <p className="cocktailDirections">
                As Executive Chef of Liberty Call Distilling since 2017, Miguel
                Valdez keeps his focus on farm-to-table fare. A native San
                Diegan, Valdez grew up in the vibrant neighborhood of Barrio
                Logan. His humble entrance into the culinary scene included
                working as a dishwasher at fifteen, and soon after delivering
                and making pizzas. Later, while working as an agricultural
                assistant at Washington State University, Valdez informally
                taught himself to cook by studying the science of organic
                produce and cultivation. Eschewing a formal culinary education,
                upon returning to San Diego he worked under chef Eric Bauer at
                the now defunct Anthology—to which Valdez credits becoming
                passionate about cooking—before landing in the kitchens of such
                acclaimed local restaurants as 100 Wines by Cohn Restaurant
                Group, The Wellington Steak &amp; Martini Lounge and The Red
                Door Restaurant &amp; Wine Bar. Today, Valdez is an in-demand
                chef highly respected for his sustainable practices and
                commitment to working with local farmers, and can often be found
                reinventing and modernizing classic dishes. He has appeared on
                Food Network’s nationally televised Guy’s Grocery Games and is
                one of three out of thousands of nominees to receive the
                National Restaurant Association’s prestigious Faces of Diversity
                American Dream Award for his hard work and perseverance. Valdez
                has also enjoyed ongoing involvement in the non-profit Berry
                Good Foundation, and in his spare time has spent hundreds of
                hours cooking for charities and volunteering to teach low-income
                mothers how to prepare healthy, cost-effective meals.
              </p>
            </div>
          </Content>
        </div>
      </SplitSection>
    </Layout>
  );
};

const team = props => (
  <Page
    transitionStatus={props.transitionStatus}
    transitionDirection="right"
    lightBackground
  >
    <meta name="theme-color" content="#000000" />
    <Team data={props.data} />
    {props.transitionStatus === 'entering' && (
      <WipeTransition animation="finishRightToLeft" />
    )}
  </Page>
);

export default team;
